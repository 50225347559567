<template>
   <div>
      <div class="paypush">
           正在跳转网银支付页面。。。
      </div>
     <form ref="payform" :action="reqUrl" 
			id="payform" method="POST">
			<input type="text" :name="fieldName" hidden :value="filedValue">
			<input type="submit" name="button" hidden value="提交" id="button">
			 
		</form>

 </div>    
</template>
<script>
export default {
    data(){
        return{
            'reqUrl':decodeURIComponent(location.href.split("=")[1].split("&")[0]) ,
				'fieldName':decodeURIComponent(location.href.split("=")[2].split("&")[0]),
				'filedValue':decodeURIComponent(location.href.split("=")[3]),
        }
    },
    mounted(){
       setTimeout(()=>{
             document.getElementById("payform").submit()
       },1000)
    },
    methods:{
      
     },
}
</script>
<style  scoped>
.paypush{
       width: 300px;
       height: 300px;
       margin: 100px auto;
       font-size: 20px;
    }
</style>
