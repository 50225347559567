<template>
   <div>
      <div class="paypush">
          <div id="test1"></div>
      </div>
     

 </div>    
</template>
<script>
import companyApi from "../../api/companyApi"
export default {
    data(){
        return{
           
        }
    },
    mounted(){
     this.getAgreement()
    },
    methods:{
      // 获取协议内容
        getAgreement(){
      companyApi.getAgreement({agreementId:"privacy"},res=>{
        if(res.data.code=='200'){
           document.getElementById("test1").innerHTML = res.data.result;
        }
        else{
             Message.error(res.data.message);
        }
       
      })
    },
     },
}
</script>
<style  scoped>
 #test1{
   font-size: 12px;
 }
</style>
